export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      render404: () => {
        const event = nuxtApp.ssrContext?.event
        const error = createError({
          statusCode: 404,
          message: 'お探しのページは見つかりませんでした。',
          fatal: true,
        })

        // Cloud Logging に `httpRequest` を含めた JSON 形式で出力
        console.error(
          JSON.stringify({
            severity: 'ERROR', // Stackdriver（Cloud Logging）上で ERROR として記録
            message: error.message,
            httpRequest: {
              requestMethod: event?.node?.req?.method || 'UNKNOWN',
              requestUrl: event?.node?.req?.url || 'UNKNOWN',
              userAgent: event?.node?.req?.headers['user-agent'] || 'UNKNOWN',
              remoteIp: event?.node?.req?.socket?.remoteAddress || 'UNKNOWN',
              status: 404,
            },
          })
        )

        return error
      },
    },
  }
})
