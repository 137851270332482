import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import {
  VBottomSheet,
  VDialog,
  VCard,
  VCardText,
  VCardActions,
  VBtn,
  VSnackbar,
  VApp,
} from 'vuetify/components'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    components: {
      VBottomSheet,
      VDialog,
      VCard,
      VCardText,
      VCardActions,
      VBtn,
      VSnackbar,
      VApp,
    },
    directives: {},
  })
  app.vueApp.use(vuetify)
})
